<template>
  <el-dialog
    v-if="isArrange"
    title="增加体温症状记录"
    :visible.sync="isArrange"
    width="40%"
    :before-close="closeArrange">
    <el-form
      style="width:100%"
      :rules="rules"
      ref="redform"
      :model="redform"
      label-width="190px">
      <el-form-item label="测量时间：" prop="collectTime">
        <el-date-picker
          style="width:90%"
          type="datetime"
          v-model="redform.collectTime"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择时间" />
      </el-form-item>

      <el-form-item label="体温：" prop="tempValue">
        <el-input style="width:90%;" v-model="redform.tempValue"></el-input>
      </el-form-item>

      <el-form-item label="有无咳嗽、乏力等症状：" prop="isymptom">
        <el-radio-group style="width:90%;" v-model="redform.isymptom">
          <el-radio label="是"></el-radio>
          <el-radio label="否"></el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item v-if="redform.isymptom == '是'" label="症状描述：" prop="symptom">
        <el-input style="width:90%" v-model="redform.symptom"></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="isArrange = false">取 消</el-button>
      <el-button type="primary" @click="jchs02()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { collectTempAndSymptom } from '../../../api/QuarantineRegistration/quarantineRegistrationpop';

export default {
  name: "InputSignDialog",
  components: {},
  data() {
    return {
      isArrange: false,
      redform: {
        collectTime: "",
        isymptom: "",
        tempValue: "",
        symptom: ""
      },
      rules: {
        collectTime: [{ required: true, message: "请选择时间", trigger: "blur" }],
        isymptom: [{ required: true, message: "请选择症状", trigger: "change" }],
        tempValue: [{ required: true, message: "请输入体温", trigger: "blur" }],
        symptom: [{ required: true, message: "请选择症状", trigger: "change" }]
      },
      regId: null
    }
  },

  methods: {
    show(item) {
      this.isArrange = true;
      this.listItem = item;
      this.regId = item.id;
      this.redform = {
        collectTime: "",
        isymptom: "",
        tempValue: "",
        symptom: ""
      }
    },

    // 关闭录入体温
    closeArrange() {
      this.isArrange = false;
    },

    jchs02() {
      this.$refs["redform"].validate(valid => {
        if (valid) {
          this.lrhs02();
        } else {
          return false;
        }
      });
    },

    // 体温接口
    async lrhs02() {
      if (this.redform.isymptom == "否") {
        this.redform.symptom = "";
      }
      let params = { ...this.redform, regId: this.regId };
      try {
        let { data } = await collectTempAndSymptom(params);
        if (data.code == 200) {
          this.$message.success("体温录入成功");
          this.isArrange = false;
          this.$parent.getIsolateDetailByRegId(this.listItem);
        } else {
          this.$message.error(data.msg);
        }
      } catch (error) {}
    }
  }
}
</script>

<style scoped lang="scss">

</style>
