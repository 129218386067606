<template> 
  <el-dialog
    title="安排隔离"
    :visible.sync="isArrange"
    width="900px"
    :before-close="closeArrange"
    :close-on-click-modal="false">

    
    <div class="arrange_content" style="padding-left: 10px;">
      <!-- 名字+性别+年龄+隔离类型 -->
      <div class="arrange_content_top">
        <div class="arrange_content_top_one">
          <span class="arrange_content_top_one_span">{{abpgeli.name}}</span>
          <span class="arrange_content_top_one_span" v-if="abpgeli.sex ==1">男</span>
          <span class="arrange_content_top_one_span" v-if="abpgeli.sex ==0">女</span>
          <span class="arrange_content_top_one_span">{{abpgeli.age}}岁</span>
          <span class="arrange_content_top_one_spantag">{{abpgeli.crowdTypeName}}</span>
        </div>
        <!-- 联系电话+证件号 -->
        <div class="arrange_content_top_two">
          <!-- 联系电话 -->
          <span class="arrange_content_top_two_span">
            <span style="color: #999999;">联系电话：</span>
            {{abpgeli.linkPhone}}
          </span>
          <!-- 证件号 -->
          <span class="arrange_content_top_two_span">
            <span style="color: #999999;">证件号：</span>
            {{abpgeli.cardNo}}
          </span>
        </div>
        <!-- 隔离点 -->
        <div class="arrange_content_top_third">
          <span class="arrange_content_top_third_span">
            <span class="arrange_content_top_third_span_span">隔离点：</span>
            {{abpgeli.isolationPointName}}
          </span>
        </div>
      </div>

      <!-- 渲染的可选择的隔离酒店房间信息，并且添加滚动条 -->
      <div class="arrange_content_bottom">
        <div class="arrange_content_bottom_floor" v-for="(item, v) in roomListInfo" :key="v">
          {{ item.floorNo }}层
          <div class="arrange_content_bottom_title">
            <div
              v-for="(list, d) in item.roomList"
              :key="d"
              @click="floor(list, d)"
              class="room-list"
              :class="{
                arrange_content_bottom_floordiv: list.liveStatus == '1',
                arrange_content_bottom_floordiv_no: list.liveStatus == '2',
                active: isActive == list.hotelRoomId
              }">
              <div class="arrange_content_bottom_floor_num">{{ list.roomNo }}</div>
              <div class="arrange_content_bottom_floor_type" v-if="list.liveStatus == 1">可用</div>
              <div class="arrange_content_bottom_floor_type" v-if="list.liveStatus == 2">不可用</div>
              <div class="arrange_content_bottom_floor_yzc">已入住: {{ list.livedCount }}</div>
              <div class="arrange_content_bottom_floor_yzc">{{ list.personNames }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="closeArrange">取 消</el-button>
      <el-button
        v-loading.fullscreen.lock="fullscreenLoading"
        size="small"
        type="primary"
        @click="arrangeEntryLive"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { arrangeEntryLive, getArrangeInfo, changeUserRoomApi } from '../../../api/QuarantineRegistration/quarantineRegistrationpop';

export default {
  name: "ArrangeIsolateDialog",
  components: {},
  data() {
    return {
      abpgeli: {},
      isArrange: false, //安排隔离弹窗
      isActive: null, //高亮
      fullscreenLoading: false,
      roomListInfo: [],
      hotelRoomId: "",
      regId: "",
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
    }
  },

  props: {
    // 此组件多页面使用，用于区分哪个页面使用的
    dialogType: {
      type: String,
      default: ""
    }
  },

  methods: {
    show(item) {
      this.isArrange = true;
      this.isActive = null;
      this.arrange(item);
    },

    // 安排隔离
    async arrange(item) {
      this.regId = item.id;
      let params = {
        regId: this.regId
      };
      try {
        let { data } = await getArrangeInfo({ params });
        this.abpgeli = item;
        this.roomListInfo = data.data;
      } catch (error) {}
    },

    // 关闭隔离弹窗
    closeArrange() {
      this.isArrange = false;
    },

    // 点击房间
    floor(item, v) {
      this.hotelRoomId = item.hotelRoomId;
      if (item.liveStatus == "1") {
        this.isActive = item.hotelRoomId;
      }
    },

    // 保存房间
    async arrangeEntryLive() {
      this.fullscreenLoading = true;
      let params = {
        hotelRoomId: this.hotelRoomId,
        regId: this.regId
      };

      let result;
      if (this.dialogType === '安排隔离') {
        result = await arrangeEntryLive({ params });
      } else if (this.dialogType === '查看详情') {
        result = await changeUserRoomApi(params);
      }

      let data = result.data;
      if (data.code == 200) {
        this.$message.success("安排成功");
        this.isArrange = false;

        if (this.dialogType === '安排隔离') {
          this.$parent.getListInfo();
        } else if (this.dialogType === '查看详情') {
          // this.$parent.getIsolateDetailByRegId(this.abpgeli)
        }
      }
      this.fullscreenLoading = false;
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../static/css/enter-dialog.scss";
@import "../../../static/css/room-list.scss";
</style>

<style scoped>
.el-dialog__wrapper >>> .el-dialog__body{
  padding: 14px 20px 8px 20px;
}
.test{
  width: 300px;
  height: 400px;
  border: 1px solid red;
}
</style>
