<template>
  <div class="main">
    <el-card v-if="issurveyResult" class="box-card">
      <div class="top01">
        <div class="top01_dxx">
          <div class="top01_xx">
            <div class="top01_xx_div el-row" style="margin-top:0">
              <span class="el-col el-col-8">开始隔离时间:
                {{ surveyResult.startTime }}</span>
              <span class="el-col el-col-8">预解除隔离时间:
                {{ surveyResult.endTime }}</span>
              <span class="el-col el-col-8">隔离天数:
                {{ surveyResult.isolationDay }}天</span>
            </div>
            <div class="top01_xx_div el-row">
              <span class="el-col el-col-8">入住时间:
                {{ surveyResult.regTime }}</span>
              <span class="el-col el-col-8">解除隔离时间:
                {{ surveyResult.transferTime }}</span>
              <span class="el-col el-col-8">实际隔离天数:
                {{ surveyResult.actualIsolationDay }}天</span>
            </div>
            <div class="top01_xx_div el-row">
              <span class="el-col el-col-8">转出目的地:
                {{ surveyResult.transferDestination }}</span>
              <span class="el-col el-col-8">转出类型:
                {{ surveyResult.transferType | transferType }}</span>
              <span class="el-col el-col-8">备注说明:
                {{ surveyResult.transferRemark }}</span>
            </div>
            <div class="top01_xx_div el-row">
              <span class="el-col el-col-8">隔离告知书状态:
                <span v-if="surveyResult.entryBookArchiveStatus == 1" class="book_download"
                      @click="downloadIamge(surveyResult.entryBookArchiveUrl,'隔离告知书')">
                      已存档
                </span>
                <span v-else>
                    未存档
                </span>
              </span>

              <span class="el-col el-col-8">解除隔离告知书状态:
                <span v-if="surveyResult.leaveBookArchiveStatus == 1" class="book_download"
                      @click="downloadIamge(surveyResult.leaveBookArchiveUrl,'解除隔离告知书')">
                      已存档
                </span>
                <span v-else>
                    未存档
                </span>
              </span>

            </div>
          </div>
          <div class="top01_xq" @click="xq">
            详情
          </div>
        </div>
        <div class="top01_xx_rz">
          <strong>入住记录</strong>
          <div class="tablel">
            <el-table :data="rztableData" style="width: 100%">
              <el-table-column prop="isolationName" label="隔离点" width="80">
              </el-table-column>
              <el-table-column prop="startTime" label="入住时间">
              </el-table-column>
              <el-table-column prop="transferTime" label="离开时间">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="bt_right">集中隔离</div>

      </div>
    </el-card>

    <el-card v-if="issurveyResult02" class="box-card" style="margin-top:30px">
      <div class="top01">
        <div class="top01_dxx">
          <div class="top01_xx">
            <div class="top01_xx_div el-row" style="margin-top:0">
              <span class="el-col el-col-8">开始隔离时间:
                {{ surveyResult02.startTime }}</span>
              <span class="el-col el-col-8">预解除隔离时间:
                {{ surveyResult02.endTime }}</span>
              <span class="el-col el-col-8">隔离天数:
                {{ surveyResult02.isolationDay }}天</span>
            </div>
            <div class="top01_xx_div el-row">
              <span class="el-col el-col-8">确认时间:
                {{ surveyResult02.regTime }}</span>
              <span class="el-col el-col-8">解除隔离时间:
                {{ surveyResult02.transferTime }}</span>
              <span class="el-col el-col-8">实际隔离天数:
                {{ surveyResult02.actualIsolationDay }}天</span>
            </div>
            <div class="top01_xx_div el-row">
              <span class="el-col el-col-8">负责社区:
                {{ surveyResult02.orgName }}</span>
              <span class="el-col el-col-8">转出目的地:
                {{ surveyResult02.transferDestination }}</span>
              <span class="el-col el-col-8">转出类型:
                {{ surveyResult02.transferType | transferType }}</span>
            </div>
            <div class="top01_xx_div el-row">
              <span class="el-col el-col-8">备注说明:
                {{ surveyResult02.transferRemark }}</span>
              <span class="el-col el-col-8">隔离告知书状态:未存档
                </span>
            </div>
          </div>

        </div>

        <div class="bt02_right">居家隔离</div>

      </div>
    </el-card>

    <el-card v-if="issurveyResult03" class="box-card" style="margin-top:30px">
      <div class="top01">
        <div class="top01_dxx">
          <div class="top01_xx">
            <div class="top01_xx_div el-row" style="margin-top:0">
              <span class="el-col el-col-8">开始隔离时间:
                {{ surveyResult03.startTime }}</span>
              <span class="el-col el-col-8">预解除隔离时间:
                {{ surveyResult03.endTime }}</span>
              <span class="el-col el-col-8">隔离天数:
                {{ surveyResult03.isolationDay }}天</span>
            </div>
            <div class="top01_xx_div el-row">
              <span class="el-col el-col-8">确认时间:
                {{ surveyResult03.regTime }}</span>
              <span class="el-col el-col-8">解除隔离时间:
                {{ surveyResult03.transferTime }}</span>
              <span class="el-col el-col-8">实际隔离天数:
                {{ surveyResult03.actualIsolationDay }}天</span>
            </div>
            <div class="top01_xx_div el-row">
              <span class="el-col el-col-8">负责社区:
                {{ surveyResult03.orgName }}</span>
              <span class="el-col el-col-8">转出目的地:
                {{ surveyResult03.transferDestination }}</span>
              <span class="el-col el-col-8">转出类型:
                {{ surveyResult03.transferType | transferType }}</span>
            </div>
            <div class="top01_xx_div el-row">
              <span class="el-col el-col-8">备注说明:
                {{ surveyResult03.transferRemark }}</span>
              <span class="el-col el-col-8">隔离告知书状态: 未存档
              </span>
            </div>
          </div>

        </div>

        <div class="bt03_right">居家健康</div>

      </div>
    </el-card>
  </div>
</template>

<script>
import DetailItem from "@/views/QuarantineRegistration/modules/DetailItem";
import { getRegInfoByRegIdApi } from "@/api/DiseaseControlManagement/DiseaseControlManagement";
import { downloadIamge } from "../../../utils/utils";
import constants from '@/common/constants'
export default {
  name: "TransferInfo",
  components: { DetailItem },
  data() {
    return {
      regLiveScreenEntity: {},
      surveyResult: {},
      surveyResult02: {},
      surveyResult03: {},
      regLiveEntity: {},
      regEntity: {},
      crowdType: '',
      zscnba: [],
      rztableData: [],
      issurveyResult: false,
      issurveyResult02: false,
      issurveyResult03: false,
    };
  },
  filters: {
    transferType(v) {
      if (v == 1) {
        return "解除管控";
      }
      if (v == 2) {
        return "送医治疗";
      }
      if (v == 3) {
        return "送往隔离点";
      }
      if (v == 4) {
        return "居家隔离";
      }
      if (v == 5) {
        return "居家监测";
      }
      if (v == 6) {
        return "街道接走";
      }
      if (v == 7) {
        return "闭环转运";
      }
      if (v == 8) {
        return "转为密接/次密接/参照密接";
      }
      if (v == 9) {
        return "其他";
      }
    },
  },
  created() {
    this.transferType = constants.TRANSFER_TYPE_SELECT;
  },
  methods: {
    // 隔离登记信息
    async getRegInfoByRegId(id, crowdType) {
      this.crowdType = crowdType;
      const params = { id: id };
      const { data } = await getRegInfoByRegIdApi(params);
      if (data.code == "200") {
        this.zscnba = data.data;

        for (let i in this.zscnba) {
          if (this.zscnba[i].surveyResult == 3 && this.zscnba[i].startTime) {

            this.issurveyResult = true;
            this.surveyResult = this.zscnba[i];
            this.rztableData = this.surveyResult.regInfoRecordList;
            continue;
          }

          if (this.zscnba[i].surveyResult == 2 && this.zscnba[i].startTime) {
            this.surveyResult02 = this.zscnba[i];
            this.issurveyResult02 = true;
            continue;
          }
          if (this.zscnba[i].surveyResult == 1 && this.zscnba[i].startTime) {
            this.surveyResult03 = this.zscnba[i];
            this.issurveyResult03 = true;
            continue;
          }
        }
      }
    },
    //下载图片
    downloadIamge(bookUrl, bookName){
      console.log(bookUrl,bookName);
      downloadIamge(bookUrl, bookName);
    },
    xq() {
      this.$emit("ee", "cc12345");
    }
  }
};
</script>

<style scoped lang="scss">

.main {
  width: 100%;
  height: 100%;
  background-color: #fff;
  font-size: 15px;
  color: #666;
}

.top01 {
  width: 100%;
  position: relative;
}

.top01_dxx {
  display: flex;
  justify-content: space-between;
}

.top01_xx {
  width: 100%;
}

.top01_xx_div {
  display: flex;
  margin-top: 20px;
}

.top01_xx_rz {
  margin-top: 30px;
}

.tablel {
  width: 60%;
}

.bt_right {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 80px;
  height: 30px;
  background-color: red;
  color: #fff;
  text-align: center;
  line-height: 30px;
}

.bt02_right {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 80px;
  height: 30px;
  background-color: #17B8DD;
  color: #fff;
  text-align: center;
  line-height: 30px;
}

.bt03_right {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 80px;
  height: 30px;
  background-color: #73D13D;
  color: #fff;
  text-align: center;
  line-height: 30px;
}

.top01_xq {
  display: flex;
  width: 100px;
  justify-content: flex-end;
  margin-top: 30px;
  cursor: pointer;
  color: #17B8DD;
}
.book_download {
  color: #3fefd0;
  text-decoration:underline;
}

.box-card {
  // width: 100%;
}
</style>