<template>
    <div class="detail-info-content">
        <div class="content_one">
            <div class="content-title">
                人员基本信息
            </div>
            <el-row>
              <DetailItem left-label="姓名" :right-value="dataList.name||'-'" />
              <DetailItem left-label="性别" :right-value="dataList.sex==='0'?'女': dataList.sex==='1' ?'男':''||'-'" />
              <DetailItem left-label="证件类型" :right-value="dataList.cardType==='1'?'居民身份证':dataList.cardType==='2'?'护照':dataList.cardType==='3'?'港澳通行证':dataList.cardType==='4'?'台胞证':'其它'||'-'" />
            </el-row>
            <el-row>
              <DetailItem left-label="证件号码" :right-value="dataList.cardNo||'-'" />
              <DetailItem left-label="联系电话" :right-value="dataList.linkPhone||'-'" />
              <DetailItem left-label="年龄" :right-value="dataList.age||'-'" />
            </el-row>
          <el-row>
              <DetailItem left-label="国籍" :right-value="dataList.country||'-'" />
              <DetailItem left-label="民族" :right-value="dataList.nation||'-'" />
              <DetailItem left-label="人群分类" :right-value="dataList.crowdTypeName||'-'" />
            </el-row>
        </div>

        <div class="content_one">
            <div class="content-title">
                <span>调查信息</span>
            </div>
            <el-row>
              <DetailItem left-label="旅居地区" :right-value="dataList.livingArea||'-'" />
              <DetailItem left-label="风险登记" :right-value="dataList.riskLevel||'-'" />
              <DetailItem left-label="来渝交通工具" :right-value="dataList.traffic||'-'" />
            </el-row>
            <el-row>
              <DetailItem left-label="航班号/车次号/车牌号" :right-value="dataList.seatNo?dataList.trafficNo + dataList.seatNo:dataList.trafficNo||'-'" />
              <DetailItem left-label="离开风险地时间" :right-value="dataList.leaveTime||'-'" />
              <DetailItem left-label="抵渝时间" :right-value="dataList.arrivalTime||'-'" />
            </el-row>
            <el-row>
              <DetailItem left-label="户籍地" :right-value="dataList.householdPlace||'-'" />
              <DetailItem left-label="目的地" :right-value="dataList.destination||'-'" />
              <DetailItem left-label="街道" :right-value="dataList.destinationStreetName||'-'" />
            </el-row>
            <el-row>
              <DetailItem left-label="居住地址" :right-value="dataList.currentAddressDetail||'-'" />
            </el-row>
        </div>
        
        <div class="content_one">
            <div class="content-title">
                <span>健康情况</span>
            </div>
            <el-row>
              <DetailItem left-label="是否有基础性疾病" :right-value="dataList.basicIllnessFlag==='1'?'是':dataList.basicIllnessFlag==='2'?'否':'不详'||'-'" />
              <DetailItem left-label="基础性疾病名称" :right-value="dataList.basicIllness||'-'" />
              <DetailItem left-label="人员类型" :right-value="dataList.personType==='0'?'正常':dataList.personType==='1'?'陪护的儿童':dataList.personType==='2'?'老年人':dataList.personType==='3'?'无自理能力的病患':dataList.personType==='4'?'孕产妇':''||'-'" />
            </el-row>
            <el-row>
              <DetailItem left-label="备注" :right-value="dataList.remark||'-'" />
            </el-row>
            <el-row>
              <DetailItem left-label="创建人" :right-value="dataList.regUser +'(' + dataList.regUserPhone + ')'||'-'" />
              <DetailItem left-label="创建人机构" :right-value="dataList.orgName||'-'" />
              <DetailItem left-label="创建时间" :right-value="dataList.regTime||'-'" />
            </el-row>
            <el-row>
              <DetailItem left-label="推送人" :right-value="dataList.treatUser +'(' + dataList.treatUserPhone + ')'||'-'" />
              <DetailItem left-label="推送机构" :right-value="dataList.treatOrgName||'-'" />
              <DetailItem left-label="推送时间" :right-value="dataList.treatTime||'-'" />
            </el-row>
        </div>
    </div>
</template>

<script>
import DetailItem from '@/views/QuarantineRegistration/modules/DetailItem';
import { getStreetRegInfo } from '@/api/QuarantineRegistration/quarantineRegistrationpop'
export default {
    components: { DetailItem },
    data(){
        return{
            streetRegId:'',
            dataList:[],
        }
    },
    methods:{
        // 街道数据请求
        async getStreetRegInfoByStreetRegId(id) {
        this.streetRegId = id;
        let { data } = await getStreetRegInfo({ id: this.streetRegId });
          if (data.code == 200) {
            this.dataList = data.data;
          }
        },
        
    }
}
</script>

<style lang="scss" scoped>
  .content-title {
    padding: 20px 0;
    color: #333;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-left: 14px;

    &:after {
      content: '';
      width: 5px;
      height: 23px;
      background: #409EFF;
      position: absolute;
      top: 22px;
      left: -12px;
    }
  }
</style>