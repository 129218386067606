<!-- 居民详情  By阿旭 -->
<template>
  <div class="main">
    <div class="top">
      <div class="top_title">
        <i class="el-icon-back" style="font-size: 20px" @click="goBack"></i>
        <span style="margin-left: 20px">居民信息</span>
      </div>

      <el-divider></el-divider>

      <div style="display: flex">
        <div class="top_content_left">
          <img style="width: 80px; height: 80px" src="../../assets/img/doctor.png" />
        </div>
        <div class="top_content_right">
          <div class="top_content_right_one_left">
            <span>{{ residentInfo.name }}</span>
            <span v-if="residentInfo.sex == '1'">男</span>
            <span v-if="residentInfo.sex == '0'">女</span>
            <span>{{ residentInfo.age }}岁</span>
            <span class="crowd-type">{{ residentInfo.crowdTypeName }}</span>
          </div>
          <div style="padding-bottom: 6px">
            <span class="user-info-item">证件类型：{{
                residentInfo.cardType | dictFilter2(cardTypeDict)
            }}</span>
            <span class="user-info-item">证件号：{{ residentInfo.cardNo }}</span>
            <span class="user-info-item">联系电话：{{ residentInfo.linkPhone }}</span>
            <span class="user-info-item">国籍：{{ residentInfo.country }}</span>
            <span class="user-info-item">居住地址：{{
                spliceString(
                  residentInfo.currentAddress,
                  residentInfo.currentAddressDetail
                )
            }}</span>
          </div>
          <el-row type="flex" justify="space-between">
            <div class="top_content_right_thr">
              <template v-if="
                residentInfo.regId && residentInfo.liveStatus=='1' && !residentInfo.epidemiologicalSurveyId
              ">
                <el-button size="medium" v-btn-permission="'008099009'" @click="openPersonalInfoDialog">修改个人信息
                </el-button>
              </template>
              <el-button size="medium" v-btn-permission="'008099010'" v-if="residentInfo.businessType=='reg' && residentInfo.liveStatus=='1'" @click="xxzl">详细资料</el-button>
              <!--<el-button size="medium" :disabled="isbnd" onclick="javascript:document.getElementById('m-cake').scrollIntoView()">录入核酸</el-button>-->
              <!--<el-button size="medium" @click="isprintbarcode = true">打印条码</el-button>-->

                <el-button size="medium" v-if="(residentInfo.businessType=='reg' && residentInfo.liveStatus=='1') || residentInfo.businessType=='homeReg'" v-btn-permission="'008099001'" :disabled="isbnd" @click="entertemperature">
                  录入体温与症状</el-button>
              <template v-if="residentInfo.businessType=='reg' && residentInfo.liveStatus=='1'">
                <el-button size="medium" v-btn-permission="'008099002'" @click="dybbg()">打印隔离告知书</el-button>
                <el-button size="medium" v-btn-permission="'008099003'" @click="printNotification">打印解除隔离告知书</el-button>
                <el-button size="medium" v-btn-permission="'008099004'" @click="transitionRoom">换房间</el-button>
                <!--<el-button size="medium" :disabled="isbnd" @click="arrange">解除隔离</el-button>-->
              </template>

              <template v-else-if="residentInfo.businessType=='homeReg'">
                <el-button size="medium" v-btn-permission="'008099002'" @click="dybbgHome()">打印隔离告知书</el-button>
              </template>
            </div>
            <div class="top_content_right_thr">
              <span style="color: #606266; font-size: 14px" @click="isShowTimeList = !isShowTimeList">展示时间节点</span>
            </div>
          </el-row>
        </div>
      </div>
      <div v-show="isShowTimeList" style="margin-top: 40px">
        <el-steps :active="jzstatus" :space="300" align-center>
          <el-step v-for="(item, index) in timeList" :key="index" :title="item.statusName"
            :description="item.dateTime" />
        </el-steps>
      </div>
    </div>

    <div style="background: #fff; padding: 0 20px">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <template v-if="isExistSurveyId">
          <el-tab-pane label="流调登记信息" name="1">
            <RegisterInfo ref="RegisterInfoRef" />
          </el-tab-pane>
          <el-tab-pane label="转运信息" name="2" v-if="surveyResult == 3">
            <TransferInfo ref="TransferInfoRef" />
          </el-tab-pane>
        </template>
        <template v-if="streetRegId">
          <el-tab-pane label="街镇登记信息" name="5">
            <StreetRegInfo ref="StreetRegInfo"/>
          </el-tab-pane>
          <el-tab-pane label="转运信息" name="2" v-if="transportFlag == 1">
            <TransferInfo ref="TransferInfoRef" />
          </el-tab-pane>
        </template>
        <template v-if="isExistRegId">
          <el-tab-pane label="隔离登记信息" name="3">
            <IsolationInfo ref="IsolationInfoRef" @ee="xxzl" />
          </el-tab-pane>
          <el-tab-pane label="检测信息" name="4">
            <CheckInfo ref="CheckInfoRef" />
          </el-tab-pane>
        </template>
      </el-tabs>
    </div>

    <!-- 提示打印条码弹窗 -->
    <el-dialog title="提示" :visible.sync="isprintbarcode" width="30%">
      <span>采样核酸将打印采样条码，请保证已连接条码打印机</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isprintbarcode = false">取 消</el-button>
        <el-button type="primary" @click="print">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 查看详情 -->
    <el-dialog v-if="xkqcc" :visible.sync="xkqcc" title="查看居民详情" width="100%" :fullscreen="true">
      <residentdetails :popdata="xqformdd" @handleClose="handleClose" />
    </el-dialog>

    <!--个人信息弹窗-->
    <EditUserInfo ref="EditUserInfoRef" />

    <!-- 录入体温与症状 弹窗 -->
    <InputSignDialog ref="InputSignDialogRef" />

    <!-- 解除隔离 弹窗 -->
    <RelieveQuarantine ref="RelieveQuarantineRef" input-type="居民详情" />

    <!-- 安排隔离 弹窗 -->
    <ArrangeIsolateDialog ref="ArrangeIsolateRef" dialog-type="查看详情" />
  </div>
</template>


<script>
import residentdetails from "./residentDetails";
import InputSignDialog from "./modules/InputSignDialog";
import RelieveQuarantine from "../Taskmanagement/modules/RelieveQuarantine";
import CheckInfo from "@/views/QuarantineRegistration/modules/CheckInfo";
import TransferInfo from "@/views/QuarantineRegistration/modules/TransferInfo";
import RegisterInfo from "@/views/QuarantineRegistration/modules/RegisterInfo";
import IsolationInfo from "@/views/QuarantineRegistration/modules/IsolationInfo";
import ArrangeIsolateDialog from "./modules/ArrangeIsolateDialog";
import EditUserInfo from "@/views/QuarantineRegistration/modules/EditUserInfo";
import StreetRegInfo from "@/views/QuarantineRegistration/modules/StreetRegInfo";
import { printBarcode } from "../../utils/utils";
import {
  downloadEntryBook,
  queryInfo,
  printCovidBarCode,
  downloadRelieveSignBook, getStreetRegInfo,
} from "../../api/QuarantineRegistration/quarantineRegistrationpop";
import { homeRegDownloadEntryBook } from "@/api/Taskmanagement/upcomingTasks.js";
import { getSurveyPersonInfoApi } from "@/api/DiseaseControlManagement/DiseaseControlManagement";
import constants from "@/common/constants";
import { SURVEY_STATUS } from "@/common/constants";
export default {
  components: {
    CheckInfo,
    TransferInfo,
    RegisterInfo,
    residentdetails,
    RelieveQuarantine,
    InputSignDialog,
    ArrangeIsolateDialog,
    EditUserInfo,
    IsolationInfo,
    StreetRegInfo,
  },
  data() {
    return {
      cardTypeDict: constants.TYPE_CERTIFICATE,
      roles: JSON.parse(localStorage.getItem("roles")),
      xqformdd: {},
      isbnd: false, //按钮点击状态
      xkqcc: false,
      isprintbarcode: false, // 打印条码弹窗
      activeName: "",
      residentInfo: {
        linkPhone: "",
      },
      linkPhone: "",
      regId: "", // 数据的id
      listItem: {}, // 列表数据
      isExistRegId: false, //是否展示隔离登记信息
      isExistSurveyId: false, //是否展示流调登记信息
      surveyResult: "", //流调结果
      jzstatus: 0,
      timeList: [], // 隔离进展-步骤条数据
      isShowTimeList: false,
      streetRegId:false,
      transportFlag:0
    };
  },

  methods: {
    // 返回上一页面
    goBack() {
      this.$parent.isShowDetail = false;
    },

    async print() {
      let params = {
        regId: this.regId,
      };
      let { data } = await printCovidBarCode({ params });
      if (data.code === "200") {
        printBarcode(data.data);
      } else {
        this.$message.error(data.msg);
      }
    },

    // 点击详细资料
    async xxzl() {
      let params = {
        id: this.regId,
      };
      try {
        let { data } = await queryInfo(params);
        this.xkqcc = true;
        this.xqformdd = data.data;
      } catch (error) { }
    },

    async printNotification() {
      let params = {
        regId: this.regId,
      };
      try {
        let { data } = await downloadRelieveSignBook({ params });
        let url = window.URL.createObjectURL(data);
        window.open(url);
      } catch (error) { }
    },

    handleClick(tab) {
      this.activeName = tab.name;
      if (this.activeName == "1") {
        this.$refs.RegisterInfoRef.getSurveyInfo(
          this.residentInfo.epidemiologicalSurveyId
        );
      } else if (this.activeName == "2") {
        this.$refs.TransferInfoRef.getTransportInfo(
          this.residentInfo.epidemiologicalSurveyId ? this.residentInfo.epidemiologicalSurveyId:this.residentInfo.streetRegId
        );
      } else if (this.activeName == "3") {
        this.$refs.IsolationInfoRef.getRegInfoByRegId(
          this.residentInfo.regId,
          this.residentInfo.crowdType
        );
      } else if (this.activeName == "4") {
        this.$refs.CheckInfoRef.getIsolateDetailByRegId(
          this.residentInfo.regId
        );
      } else if (this.activeName == "5") {
        this.$refs.StreetRegInfo.getStreetRegInfoByStreetRegId(
          this.streetRegId
        );
      }
    },

    // 显示个人信息弹窗
    openPersonalInfoDialog() {
      const listItem = { ...this.residentInfo, id: this.regId };
      this.$refs.EditUserInfoRef.show(listItem);
    },
    // 换房间
    transitionRoom() {
      this.$refs.ArrangeIsolateRef.show(this.listItem);
    },

    // 录入体温
    entertemperature() {
      this.$refs.InputSignDialogRef.show(this.listItem);
    },

    // 安排隔离
    arrange() {
      this.$refs.RelieveQuarantineRef.show(this.listItem);
    },

    // 打印报告
    async dybbg() {
      let params = {
        regId: this.regId,
      };
      try {
        let { data } = await downloadEntryBook({ params });
        let url = window.URL.createObjectURL(data);
        window.open(url);
      } catch (error) { }
    },
    // 打印居家隔离报告
    async dybbgHome() {
      let params = {
        regId: this.regId,
      };
      try {
        let { data } = await homeRegDownloadEntryBook({ params });
        let url = window.URL.createObjectURL(data);
        window.open(url);
      } catch (error) { }
    },

    handleClose() {
      this.xkqcc = false;
      this.$refs.CheckInfoRef.getIsolateDetailByRegId(this.listItem);

      let tab = {
        name: '3'
      }
      this.handleClick(tab)
      this.getSurveyPersonInfo()

    },

    //显示个人信息
    async getSurveyPersonInfo(row) {
      this.listItem = row ? row : this.listItem;
      this.regId = row ? row.id : this.regId;
      const params = { id: this.regId };
      const { data } = await getSurveyPersonInfoApi({ params });
      if (data.code == "200") {
        this.residentInfo = data.data;
        this.timeList = data.data.timeList;
        // 隔离进展
        for (let i in this.timeList) {
          if (this.timeList[i].status == 1) {
            this.jzstatus = ++i;
          }
        }
        let { epidemiologicalSurveyId, regId,streetRegId } = this.residentInfo;
        // 流调信息
        if (!!epidemiologicalSurveyId) {
          this.activeName = "1";
          this.isExistSurveyId = true;
          this.surveyResult = this.residentInfo.surveyResult;
        }
        // 隔离登记信息
        if (!!regId) {
          this.activeName = this.activeName == "1" ? this.activeName : "3";
          this.isExistRegId = true;
        }
        // 街镇登记信息
        if(!!streetRegId){
          this.activeName = '5';
          this.streetRegId = data.data.streetRegId;
          this.transportFlag = data.data.transportFlag;
          this.$nextTick(() => {
            this.$refs.StreetRegInfo.getStreetRegInfoByStreetRegId(streetRegId);
          });
        }
        if (this.activeName == "1") {
          this.$nextTick(() => {
            this.$refs.RegisterInfoRef.getSurveyInfo(epidemiologicalSurveyId);
          });
        } else if (this.activeName == "3") {
          this.$nextTick(() => {
            this.$refs.IsolationInfoRef.getRegInfoByRegId(
              regId,
              this.residentInfo.crowdType
            );
          });
        }
      }
    },
    //处理多字段拼接
    spliceString(val1, val2) {
      if (!val1 && !val2) {
        return "-";
      } else if (val2 == null) {
        return val1;
      } else {
        return val1 + val2;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;

  ::v-deep {
    .el-form-item__label {
      width: 90px;
    }
  }
}

.top {
  background-color: #fff;
  padding: 14px;
  box-sizing: border-box;
}

.top_title {
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  color: #666666;
}

.top_content_left {
  margin-right: 36px;
}

.top_content_right_one_left {
  display: flex;
  margin-bottom: 20px;
  color: #333;
  font-size: 18px;
  font-weight: bold;

  span {
    margin-right: 16px;
  }

  .crowd-type {
    margin-left: 30px;
    color: #fff;
    font-weight: normal;
    font-size: 12px;
    text-align: center;
    background-color: #3476f5;
    border-radius: 5px;
    padding: 4px 8px;
  }
}

.top_content_right {
  width: 100%;
}

.user-info-item {
  display: inline-block;
  margin-right: 30px;
  font-size: 13px;
  color: #999999;
}

.top_content_right_thr {
  margin-top: 10px;
  color: #e4e4e4;
}
</style>