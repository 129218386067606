<!-- 未确认人员信息 by阿旭 -->
<template>
  <div>
    <div v-if="!isShowDetail">
      <!-- 确认人员 -->
      <el-row type="flex" justify="space-between" align="middle">
        <el-col :span="20">
          <el-input style="width:220px;margin-right:20px" v-model="cx.searchValue" placeholder="居民姓名/证件号">
          </el-input>

          <el-select style="margin-right:20px" v-model="cx.cardType" placeholder="证件类型" class="select-width">
            <el-option v-for="item in typeCertificate" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-select style="width:230px;margin-right:20px" v-model="cx.controlOrgCode" filterable clearable placeholder="负责机构">
            <el-option v-for="item in homeIsolationOptions" :key="item.value" :label="item.orgName" :value="item.orgCode">
            </el-option>
          </el-select>
          <el-radio-group v-model="cx.controlMethod">
            <el-radio :label="0">全部</el-radio>
            <el-radio :label="1">居家监测</el-radio>
            <el-radio class="homeIso" :label="2">居家隔离</el-radio>
          </el-radio-group>

          <el-date-picker v-model="timetime" size="medium" type="daterange" value-format="yyyy-MM-dd"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
        </el-col>
        <el-col :span="4" style="text-align:right">
          <el-button class="searchBtn" type="primary" @click="queryPerson('queryBtn')">查询</el-button>
          <el-button type="primary" @click="exportFormInfo" v-loading.fullscreen.lock="fullscreenLoading">导出</el-button>
        </el-col>
      </el-row>
      <div class="top_table">
        <el-table :data="tableData" :loading="loading" style="width: 100%" height="800"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="45"></el-table-column>
          <el-table-column fixed="left" label="操作" width="200">
            <template slot-scope="scope">
              <el-button class="changeBtn" @click="handleClick(scope.row)" type="text" size="small" v-btn-permission="'008011003001'">查看信息</el-button>
              <el-button class="changeBtn" @click="changeResponsible(scope.row)" type="text" size="small" v-btn-permission="'008011003002'">更改负责部门</el-button>
              <el-button class="changeBtn" @click="dictionaryFun(scope.row)" type="text" size="small" v-btn-permission="'008011003005'">打印告知书</el-button>
              <el-button class="changeBtn" @click="confirmInformation(scope.row)" type="text" size="small" v-btn-permission="'008011003003'">确认信息</el-button>
              <el-button class="changeBtn" @click="removeHomeReg(scope.row)" type="text" size="small" v-btn-permission="'008011003004'">删除</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="居民姓名" width="160"></el-table-column>
          <el-table-column prop="age" label="年龄" width="160">
          </el-table-column>
          <el-table-column prop="sex" label="性别" width="120">
            <template slot-scope="scope">
              <span>{{ scope.row.sex | sex }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="cardType" label="证件类型" width="120">
            <template slot-scope="scope">
              <span>{{ scope.row.cardType | typeid }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="cardNo" label="证件号码" width="180"></el-table-column>
          <el-table-column prop="linkPhone" label="联系电话" width="120"></el-table-column>
          <el-table-column prop="currentAddress" label="居住地址" width="180">
            <template  slot-scope="scope">
              <span  v-if="scope.row.currentAddress !=null" >{{  scope.row.currentAddress}}</span>
              <span  v-if="scope.row.currentAddressDetail !=null" >{{  scope.row.currentAddressDetail}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="crowdTypeName" label="人群分类" width="160"></el-table-column>
          <el-table-column prop="crtTime" label="登记时间" width="160"></el-table-column>
          <el-table-column prop="controlMethod" label="管控方式" width="160">
            <template slot-scope="scope">
             <span style="background-color: #409eff;color: #fff;padding: 0px 10px;border-radius: 10px; float: left" v-if="scope.row.controlMethod == 1">{{
                 scope.row.controlMethod |
                     typerw
               }}</span>
              <span style="background-color: #f56c6c;color: #fff;padding: 0px 10px;border-radius: 10px; float: left" v-if="scope.row.controlMethod != 1">{{
                  scope.row.controlMethod | typerw
                }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="controlOrgName" label="管控社区" width="160"></el-table-column>
          <el-table-column prop="regTime" label="管控开始时间" width="160">
            <template slot-scope="scope">
              <span style="float: left"> {{scope.row.startTime ? scope.row.startTime : scope.row.regTime }}</span>
            </template>
          </el-table-column>//和小慧确认目前展示登记时间 wangwx
          <el-table-column prop="endTime" label="预计解除隔离时间" width="160"></el-table-column>
          <el-table-column prop="regChannel" label="信息来源" width="160">
            <template slot-scope="scope">
             <span style="float: left">{{ scope.row.regChannel | regChannel }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="regUser" label="推送人员" width="200">
            <template  slot-scope="scope">
            {{scope.row.regUser ? scope.row.regUser : "" }}{{ scope.row.regUserPhone ? "("+scope.row.regUserPhone+")" : "" }}
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="blankbox"></div>

      <div class="top_operate" v-btn-permission="'008011003003'">
        <div style="margin-right:20px">已选中{{ multipleSelection }}项目</div>
        <div style="margin-right:20px">批量操作</div>
        <div style="margin-right:20px">
          <el-button type="primary" :disabled="multipleSelection == 0" @click="multipleConfirmInformation">确认信息</el-button>
        </div>

        <div class="content_page">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="pageNumber" :total="total" :page-sizes="[20, 40, 60, 80]" :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper" />
        </div>
      </div>

     

    </div>
    <!-- 居民详情 -->
    <div v-if="isShowDetail">
      <residentInformation ref="residentInformationRef" />
    </div>
    <!-- 更改负责部门 -->
    <el-dialog title="更改负责部门" :visible.sync="ischangeResponsible" width="30%">
      <div>
        负责机构：<el-select v-model="dddvalue" filterable placeholder="请选择" @change="sqfw">
          <el-option v-for="item in sqoptions" :key="item.value" :label="item.orgName" :value="item.orgCode">
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ischangeResponsible = false">取 消</el-button>
        <el-button type="primary" @click="qddischangeResponsible">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 删除人员信息 -->
    <el-dialog title="删除人员信息" :visible.sync="isHomeReg" width="32%">
      <div style="width: 100%">
        <div style="width:100%;display: flex;justify-content: center;">
          <div style="width:400px;">
            <p style="padding-bottom: 14px;">确定删除当前登记用户信息吗？</p>
          </div>
          
        </div>
        <div style="width:100%;display: flex;justify-content: center;">
          <el-input style="width:400px;" :rows="5" type="textarea" v-model="deleteRemark" placeholder="请说明原因(必填)" maxlength="50"  show-word-limit></el-input>
        </div>
      </div>
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="isHomeReg = false">取 消</el-button>
        <el-button type="primary" @click="removeHomeRegById">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import residentInformation from "@/views/QuarantineRegistration/residentInformation";
import TableModel from "@/components/TableModel";
import { mapState } from "vuex";
import {
  exportWaitList,
  updateOrg,
  waitList,
  deleteid,
  confirm,
  homeRegDownloadEntryBook
} from "@/api/Taskmanagement/upcomingTasks.js";
import constants from "@/common/constants";
import { SURVEY_RESULT } from "@/common/constants";
import { getBusinessOrgList } from "@/api/SystemManagement/index";
import { importFile, messageBox } from "../../utils/utils";
import DialogInfo from "@/components/DialogInfo.vue"
export default {
  components: { residentInformation, TableModel, DialogInfo },
  data() {
    return {
      typeCertificate: constants.TYPE_CERTIFICATE.filter((item, i) => i !== 0), //身份证类型
      fullscreenLoading: false,
      multipleSelection: 0,
      isShowDetail: false,
      ischangeResponsible: false,
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      ruleForm: {},
      cx: {
        searchValue: "",
        controlMethod: 0
      },
      isHomeReg:false,
      homeReg:{},
      deleteRemark:'',
      homeIsolationOptions: [],
      sqoptions: [],
      timetime: [],
      value: "",
      checkdata: [], //待确认人员数组
      tableData: [],
      options: [],
      loading: false,
      dddvalue: "",
      pageNumber: 1,
      pageSize: 20,
      total: 0,
    };
  },
  filters: {
    typeid(val) {
      if (val == 1) {
        val = "居民身份证";
        return val;
      } else if (val == 2) {
        val = "护照";
        return val;
      } else if (val == 3) {
        val = "港澳通行证";
        return val;
      } else if (val == 4) {
        val = "台胞证";
        return val;
      } else if (val == 5) {
        val = "其他";
        return val;
      }
    },
    typerw(val) {
      if (val == 1) {
        val = "居家监测";
        return val;
      } else {
        val = "居家隔离";
        return val;
      }
    },
    regChannel(val) {
      if (val == 0) {
        return "现场";
      } else if (val == 1){
        return "小程序";
      } else if (val == 2){
        return "隔离点转出";
      } else if (val == 3){
        return "疾控流调";
      } else if (val == 4){
        return "居家隔离转出";
      } else if (val == 5){
        return "居家监测转出";
      }else if (val == 6){
        return "街镇转出";
      }else {
        return "";
      }
    },
    sex(val) {
      if (val == 1) {
        val = "男";
        return val;
      } else {
        val = "女";
        return val;
      }
    },
  },
  computed: {
    ...mapState({
      typepopSelect: (state) => state.user.typepopSelect, // 人群分类
    }),
  },
  created() {
    this.surveyResult = SURVEY_RESULT;
    this.queryPerson();
    this.orgHomeIsolationList();
    this.orgQueryList();
  },
  mounted() {
  },

  methods: {
    //分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPerson();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.queryPerson();
    },

    //待确认人员处理选择框
    handleSelectionChange(val) {
      this.multipleSelection = val.length;
      this.checkdata = val;
    },
    // 查询待转运人员
    async queryPerson(v) {
      if(v=='queryBtn'){
        this.pageNumber = 1 ;
      }
      this.loading = true
      let params = {
        ...this.cx,
        pageNumber:this.pageNumber,
        pageSize:this.pageSize

      };
      if (this.timetime && this.timetime.length == 2) {
        params.regTimeMin = this.timetime[0] + " 00:00:00";
        params.regTimeMax = this.timetime[1] + " 23:59:59";
      }
      const  res = await waitList({ params })
      // console.log(res.data,'打印dataaa111a')
      if (res.data.code == '200') {
        this.tableData = res.data.data.list;
        this.total = res.data.data.totalRow
        setTimeout(() => {
          this.loading = false;
        }, 100);
      }
    },
    // 负责机构(居家隔离业务)
    orgHomeIsolationList() {
      //加载机构居家隔离业务类型为是的(orgCode查询)
      let params = {
        homeIsolation: 1,
        orgCode: this.userdoctor.orgCode
      }
      getBusinessOrgList({params}).then(res => {
        this.homeIsolationOptions = res.data.data;
      });
    },
    // 负责机构
    orgQueryList() {
      //加载机构居家隔离业务类型为是的
      let params = { homeIsolation: 1 }
      getBusinessOrgList({params}).then(res => {
        this.sqoptions = res.data.data;
      });
    },
    handleClick(row) {
      this.isShowDetail = true;
      this.$nextTick(() => {
        this.$refs.residentInformationRef.getSurveyPersonInfo(row);
      });
    },
    // 更改负责部门
    changeResponsible(row) {
      this.gxpop = row
      this.ischangeResponsible = true
    },
    // 弹窗更改负责部门
    async qddischangeResponsible() {
      let params = {
        controlOrgName: this.controlOrgName,
        controlOrgCode: this.controlOrgCode,
        id: this.gxpop.id
      };
      try {
        let { data } = await updateOrg(params);
        if (data.code == 200) {
          // _this.tableData = data.data;
          this.queryPerson();
          this.$message({
            message: "修改成功！",
            type: "success"
          });
        }
      } catch (error) { }

      this.ischangeResponsible = false
    },
    // 确认信息
    async confirmInformation(row) {
      let params = [row.id];
      try {
        let { data } = await confirm(params);
        if (data.code == 200) {
          this.queryPerson();
          this.$message({
            message: "信息确认成功",
            type: "success"
          });
        }
      } catch (error) {
        this.$message.error(error);
       }
    },
    //批量确认信息
    async multipleConfirmInformation() {
      let ids = [];
      this.checkdata.forEach((cdata) =>{
        ids.push(cdata.id);
      })
      try {
        let { data } = await confirm(ids);
        if (data.code == 200) {
          this.queryPerson();
          this.$message({
            message: "发送成功！",
            type: "success"
          });
        }
      } catch (error) { }
    },
    // 删除人员信息弹窗
    removeHomeReg(row) {
      this.homeReg = row;
      this.isHomeReg = true;
    },

    // 删除人员信息
    async removeHomeRegById() {
      let params = {
        deleteRemark: this.deleteRemark,
        id: this.homeReg.id
      };
      try {
        let { data } = await deleteid({params});
        if (data.code == 200) {
          this.$message({
            message: "删除成功！",
            type: "success"
          });
          this.deleteRemark="";
          this.isHomeReg = false;
          this.queryPerson();
        }
      } catch (error) { }
    }, // 下载打印
    async dictionaryFun(v) {
      let params = {
        regId: v.id
      };
      let { data } = await homeRegDownloadEntryBook({ params });
      let url = window.URL.createObjectURL(data);
      window.open(url);
    },
    // 导出
    exportFormInfo() {
      let params = {
        ...this.cx,
        hospitalId: this.userdoctor.hospitalId,
        orgCode: this.userdoctor.orgCode,
      };
      if (this.timetime && this.timetime.length == 2) {
        params.regTimeMin = this.timetime[0] + " 00:00:00";
        params.regTimeMax = this.timetime[1] + " 23:59:59";
      }
      messageBox(
        () => {
          this.fullscreenLoading = true;
          exportWaitList({ params }).then((res) => {
            // console.log(11111111111, res);
            this.fullscreenLoading = false;
            importFile(res.data, "未确认人员信息");
          });
        },
        "warning",
        "是否确认导出 未确认人员信息 数据？"
      );
    },
    sqfw(v) {
      for (let i in this.sqoptions) {
        if (this.sqoptions[i].orgCode == this.dddvalue) {
          this.controlOrgName = this.sqoptions[i].orgName;
          this.controlOrgCode = this.sqoptions[i].orgCode;
        }
      }
    }

  }
};
</script>
<style lang='scss' scoped>
.top_input {
  display: flex;
}

.top_table {
  margin-top: 10px;
  margin-bottom: 20px;
}

.blankbox{
  height: 100px;
}

.top_operate {
  height: 100px;
  width: 100%;
  background: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0px;
  z-index: 99;
}

.dialog_msg {
  display: flex;
  margin-bottom: 10px;
}

.dialog_msg_name {
  margin-right: 14px;


  .dialog_msg_name_id {
    padding: 4px;
    color: #999999;
  }
}



.dialog_msg_type {
  margin-right: 14px;
  padding: 4px;
  background-color: #F90101;
  color: #fff;
}


.dialog_msg_type02 {
  margin-right: 14px;
}


.dialog_msg_typebsmj {
  margin-right: 14px;
  padding: 4px;
  color: #fff;
  background-color: #0079FE;
}

table {
  border-collapse: collapse;
}

table tr td {
  border: 1px solid #d4dedd;
}

.changeBtn{
  margin-left: 0px;
  margin-right: 10px;
}

.searchBtn{
  margin-right: 15px;
}

.homeIso{
  margin-right: 15px;
}

.td1 {
  padding: 0 10px;

  span {
    background-color: #0079fe;
    color: #fff;
  }
}

.td0,
.td2,
.td3,
.td4,
.td5,
.td6 {
  padding: 10px 10px;
}

.td6 {
  display: flex;
}

.content_page {
  position: fixed;
  bottom: 20px;
  right: 0;
  margin-top: 10px;
  height: 40px;
}

.jjglclass {
  background-color: red;
  color: #fff;
}

.jjjcclass {
  background-color: red;
  color: #1890FF;
}
/deep/.el-dialog__body{
  padding-top:10px ;
  padding-bottom: 10px;
}
// /deep/.el-textarea__inner{
//   min-height: 100px;
// }
</style>