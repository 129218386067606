<template>
  <el-dialog
    title="个人信息"
    @close="closePersonalInfoDialog"
    :visible.sync="dialogPersonalInfo"
  >
    <el-alert
      v-if="disablePersonalInfo"
      type="warning"
      title="此人员已经做过核酸采样，部分信息不能修改"
      :closable="false"
      style="margin-bottom: 20px"
      show-icon
    >
    </el-alert>

    <el-form :model="personalInfo">
      <el-row type="flex" :gutter="20">
        <el-col :span="12">
          <el-form-item label="姓名：" prop="name">
            <el-input
              :disabled="disablePersonalInfo"
              style="width: 70%"
              v-model="personalInfo.name"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="证件类型：" prop="cardType">
            <el-select
              :disabled="disablePersonalInfo"
              style="width: 70%"
              v-model="personalInfo.cardType"
              placeholder="证件类型"
            >
              <el-option
                v-for="item in typeCertificate"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex" :gutter="20">
        <el-col :span="12">
          <el-form-item label="证件号：" prop="cardNo">
            <el-input
              :disabled="disablePersonalInfo"
              style="width: 70%"
              v-model="personalInfo.cardNo"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="性别：" prop="sex">
            <el-radio-group style="width: 70%" v-model="personalInfo.sex">
              <el-radio label="1">男</el-radio>
              <el-radio label="0">女</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex" :gutter="20">
        <el-col :span="12">
          <el-form-item label="年龄：" prop="age">
            <el-input
              type="number"
              style="width: 70%"
              v-model="personalInfo.age"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系电话：" prop="linkPhone">
            <el-input
              :disabled="disablePersonalInfo"
              style="width: 70%"
              v-model="personalInfo.linkPhone"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex" :gutter="20">
        <el-col :span="12">
          <el-form-item label="国籍：" prop="country">
            <el-select
              filterable
              placeholder="请选择国籍"
              style="width: 70%"
              v-model="personalInfo.country"
            >
              <el-option
                v-for="item in nationalityselet"
                :key="item.label"
                :label="item.label"
                :value="item.label"
              >
                <span style="float: left">{{ item.label }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                  item.value
                }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="closePersonalInfoDialog">取 消</el-button>
      <el-button size="small" type="primary" @click="updatePersonalInfo"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { updatePersonalInfo } from "@/api/RuleManagement/crowdAnalysis";
import constants from "@/common/constants";

export default {
  name: "EditUserInfo",
  components: {},
  data() {
    return {
      disablePersonalInfo: false, //是否禁用个人信息修改功能
      dialogPersonalInfo: false, //个人信息弹窗
      personalInfo: {
        name: "",
        cardType: "",
        cardNo: "",
        sex: "",
        age: "",
        linkPhone: "",
        country: "",
      },
      nationalityselet: JSON.parse(localStorage.getItem("nationalityselet")), //国籍选项
      typeCertificate: constants.TYPE_CERTIFICATE.filter((item, i) => i !== 0), //身份证类型
      listItem: {}, // 列表数据
    };
  },

  methods: {
    show(listItem) {
      this.dialogPersonalInfo = true;
      this.listItem = listItem;
      // 判断是否有核酸检测
      // detects.forEach(item => {
      //   if (item.status !== '0') this.disablePersonalInfo = true
      // })
      this.personalInfo.id = listItem.id;
      this.personalInfo.name = listItem.name;
      this.personalInfo.cardType = listItem.cardType;
      this.personalInfo.cardNo = listItem.cardNo;
      this.personalInfo.sex = listItem.sex;
      this.personalInfo.age = listItem.age;
      this.personalInfo.linkPhone = listItem.linkPhone;
      this.personalInfo.country = listItem.country;
      this.$forceUpdate();
    },

    // 关闭个人信息弹窗
    closePersonalInfoDialog() {
      this.dialogPersonalInfo = false;
      this.disablePersonalInfo = false;
      this.personalInfo = {
        name: "",
        cardType: "",
        cardNo: "",
        sex: "",
        age: "",
        linkPhone: "",
        country: "",
      };
    },

    // 修改个人信息
    updatePersonalInfo() {
      updatePersonalInfo(this.personalInfo).then((res) => {
        if (res.data.code === "200") {
          this.$message.success(res.data.msg);
          this.$parent.getSurveyPersonInfo();
          this.closePersonalInfoDialog();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
