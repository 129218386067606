<template>
  <div class="detail-info-content">
    <div class="content_one">
      <div class="content-title">任务基本信息</div>
      <el-row>
        <DetailItem left-label="任务编号" :right-value="transferInfo.taskNo||'-'" />
        <DetailItem left-label="开始时间" :right-value="transferInfo.startTime||'-'" />
        <DetailItem left-label="结束时间" :right-value="transferInfo.endTime||'-'" />
      </el-row>
      <el-row>
        <DetailItem left-label="任务分配人" :right-value="transferInfo.taskCreatUserName||'-'" />
        <DetailItem left-label="转运负责社区" :right-value="transferInfo.responsibleOrgName||'-'" />
        <DetailItem left-label="转运社区人员" :right-value="transferInfo.responsibleOrgLeaderName||'-'" />
      </el-row>
      <el-row>
        <DetailItem left-label="转运司机" :right-value="transferInfo.transportDriverName||'-'" />
        <DetailItem left-label="隔离酒店" :right-value="transferInfo.isolationPointName||'-'" />
      </el-row>
    </div>

    <div class="content_one">
      <div class="content-title">任务关联居民</div>
      <el-table :data="transferInfo.taskRelationResidentsList"
        :header-cell-style="{ background: '#ffffff', color: '#909399' }" size="medium" :max-height="400"
        style="width: 100%;">
        <el-table-column prop="name" label="姓名" />
        <el-table-column prop="sex" label="性别">
        <template slot-scope="scope">
          {{ scope.row.sex|sexFilter() }}
        </template>
        </el-table-column>
        <el-table-column prop="age" label="年龄" />
        <el-table-column prop="cardType" label="证件类型">
          <template slot-scope="scope">
            {{ scope.row.cardType | dictFilter2(cardTypeDict) }}
          </template>
        </el-table-column>
        <el-table-column prop="cardNo" label="证件号码" />
        <el-table-column prop="crowdTypeName" label="人群分类" />
        <el-table-column prop="eventName" label="事件名称">
          <template slot-scope="scope">
            {{ scope.row.eventName||'-' }}
          </template>
          </el-table-column>
      </el-table>
    </div>

    <div class="content_one">
      <div class="content-title">任务处理时间点</div>
      <el-table :data="transferInfo.transportTaskFlowInfoList"
        :header-cell-style="{ background: '#ffffff', color: '#909399' }" size="medium" :max-height="400"
        style="width: 100%;">
        <el-table-column prop="flowName" label="流程" />
        <el-table-column prop="flowTime" label="时间" />
        <el-table-column prop="name" label="处理人" />
        <el-table-column prop="remark" label="备注">
          <template slot-scope="scope">
            {{ scope.row.remark||'-' }}
          </template>
          </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
  import DetailItem from '@/views/QuarantineRegistration/modules/DetailItem'
  import { getTransportInfoApi } from '@/api/DiseaseControlManagement/DiseaseControlManagement';
  import constants from "@/common/constants";
  export default {
    name: "TransferInfo",
    components: { DetailItem },
    data() {
      return {
        transferInfo: {
          transportTaskFlowInfoList: [],
          taskRelationResidentsList: [],
        },
      }
    },
    created() {
      this.cardTypeDict = constants.TYPE_CERTIFICATE;
    },
    mounted() {
    },
    methods: {
      //转运详情
      async getTransportInfo(val) {
        const params = { id: val }
        const { data } = await getTransportInfoApi({ params });
        if (data.code == '200') {
          this.transferInfo = data.data;
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  .content-title {
    padding: 20px 0;
    color: #333;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-left: 14px;

    &:after {
      content: '';
      width: 5px;
      height: 23px;
      background: #409EFF;
      position: absolute;
      top: 22px;
      left: -12px;
    }
  }
</style>